.banner1-container1 {
  gap: var(--dl-space-space-unit);
  width: 1198px;
  height: 134px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
}
.banner1-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.banner1-container2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.banner1-text {
  display: inline-block;
  font-size: 40px;
  font-family: "Poppins";
}
