.home1-container {
  width: 1400px;
  height: 1930px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.home1-column {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 25px;
  flex: 1;
  left: -4px;
  height: 992px;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home1-content {
  gap: 24px;
  width: 418px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home1-image1 {
  top: -30px;
  right: 5px;
  width: 492px;
  height: 956px;
  opacity: 0.6;
  position: absolute;
  align-self: center;
}
.home1-image {
  top: 123px;
  left: 659px;
  width: 440px;
  height: 506px;
  position: absolute;
  object-fit: cover;
}
.home1-text10 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 900;
  color: black;
  
}
.home1-text11 {
  width: 442px;
  display: inline-block;
  font-family: "Poppins";
}
.home1-text12 {
  width: 525px;
  height: 370px;
  display: inline-block;
  font-family: "Poppins";
}
.home1-text13 {
  font-weight: 700;
}

@media(max-width: 991px) {
  .home1-container {
    flex-direction: column;
  }
  .home1-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .home1-text11 {
    text-align: center;
  }
  .home1-text12 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .home1-text11 {
    text-align: center;
  }
  .home1-text12 {
    text-align: center;
  }
}
