.blog-container {
  width: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.blog-text10 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  width: var(--dl-size-size-maxwidth);
  height: auto;
  display: inline-block;
  font-size: 35px;
  font-family: "Poppins";
  font-weight: 700;
  white-space: nowrap;
}
.blog-text11 {
  display: inline-block;
  text-decoration: none;
}
.blog-text12 {
  display: inline-block;
}
.blog-text15 {
  display: inline-block;
  text-decoration: none;
}
.blog-text16 {
  display: inline-block;
}
.blog-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.blog-text18 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.blog-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.blog-text20 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.blog-text21 {
  display: inline-block;
  text-decoration: none;
}
.blog-text22 {
  display: inline-block;
}
.blog-text23 {
  display: inline-block;
}
.blog-text24 {
  display: inline-block;
}
.blog-text25 {
  display: inline-block;
}
.blog-text26 {
  display: inline-block;
}
.blog-text27 {
  display: inline-block;
}
.blog-text28 {
  display: inline-block;
  font-size: 40px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 800;
}
.blog-text29 {
  display: inline-block;
}
.blog-text30 {
  display: inline-block;
}
.blog-text31 {
  display: inline-block;
}
.blog-text32 {
  display: inline-block;
}
.blog-text33 {
  display: inline-block;
}
.blog-text34 {
  display: inline-block;
}
.blog-text35 {
  display: inline-block;
}
.blog-text36 {
  display: inline-block;
}
.blog-text37 {
  display: inline-block;
}
.blog-text38 {
  display: inline-block;
}
.blog-text39 {
  display: inline-block;
}
.blog-text40 {
  display: inline-block;
}
