.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: auto;
  align-items: center;
  flex-direction: column;
}

.home-text10 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  width: var(--dl-size-size-maxwidth);
  height: auto;
  display: inline-block;
  font-size: 35px;
  font-family: "Poppins";
  font-weight: 700;
  white-space: nowrap;
  /* Add this line to make the text in a single line */
}

.home-text11 {
  display: inline-block;
  text-decoration: none;
}

.home-text12 {
  display: inline-block;
}

.home-text15 {
  display: inline-block;
  text-decoration: none;
}

.home-text16 {
  display: inline-block;
}

.home-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.home-text18 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.home-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.home-text20 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.home-text21 {
  display: inline-block;
  text-decoration: none;
}

.home-text22 {
  display: inline-block;
}

.home-text23 {
  display: inline-block;
}

.home-text24 {
  display: inline-block;
}

.home-text25 {
  display: inline-block;
}

.home-text26 {
  display: inline-block;
}

.home-text27 {
  display: inline-block;
}

.home-text28 {
  width: 525px;
  height: auto;
  display: inline-block;
  font-family: "Poppins";
}

.home-text29 {
  font-weight: 700;

}

.home-text36 {
  width: 442px;
  display: inline-block;
  font-family: "Poppins";

}

.home-text30 {
  display: inline-block;
  font-family: "Poppins";
  text-align: left;
  width: 800px;
  height: auto;
}

@media(max-width: 767px) {
  .home-text28 {
    text-align: center;
  }

  .home-text36 {
    text-align: center;
  }
}

@media(max-width: 479px) {
  .home-text28 {
    text-align: center;
  }

  .home-text36 {
    text-align: center;
  }
}