.blog1-layout349 {
  height: 250px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.blog1-max-width {
  gap: var(--dl-space-space-fiveunits);
  width: 1124px;
  height: 50%;
  display: flex;
  align-items: center;
}
.blog1-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.blog1-placeholder-image {
  width: 471px;
  height: 121px;
}
.blog1-image-container {
  flex: 1;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  position: relative;
  align-items: center;
}
.blog1-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog1-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.blog1-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.blog1-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
}
.blog1-button {
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}

@media(max-width: 991px) {
  .blog1-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .blog1-actions {
    width: 100%;
    flex-direction: column;
  }
  .blog1-button {
    width: 100%;
  }
}
