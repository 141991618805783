.gallery2-gallery3 {
  width: 100%;
  height: 945px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery2-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 974px;
  height: 800px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.gallery2-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery2-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 101px;
  left: 0px;
  width: 100%;
  height: 174px;
  display: flex;
  position: absolute;
  align-self: stretch;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
.gallery2-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image11 {
  width: 100%;
  max-width: 600px;
  align-self: center;
}
.gallery2-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image21 {
  width: 100%;
  max-width: 600px;
  align-self: center;
}
.gallery2-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image31 {
  width: 100%;
  max-width: 600px;
}
.gallery2-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 315px;
  left: -1px;
  width: 100%;
  display: flex;
  position: absolute;
  align-self: stretch;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
.gallery2-container4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image12 {
  width: 100%;
  max-width: 600px;
  align-self: center;
}
.gallery2-container5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image22 {
  width: 100%;
  max-width: 600px;
  align-self: center;
}
.gallery2-container6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image32 {
  width: 100%;
  max-width: 600px;
}
.gallery2-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  right: -1px;
  width: 100%;
  bottom: -10px;
  display: flex;
  position: absolute;
  align-self: stretch;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
.gallery2-container7 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image13 {
  width: 100%;
  max-width: 600px;
  align-self: center;
}
.gallery2-container8 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image23 {
  width: 100%;
  max-width: 600px;
  align-self: center;
}
.gallery2-container9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery2-image33 {
  width: 100%;
  max-width: 600px;
}
.gallery2-text2 {
  display: inline-block;
  font-family: "popins";
  font-size: 16px;
}
@media(max-width: 991px) {
  .gallery2-content1 {
    align-items: center;
    flex-direction: column;
  }
  .gallery2-image11 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-image21 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-image31 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-content2 {
    align-items: center;
    flex-direction: column;
  }
  .gallery2-image12 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-image22 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-image32 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-content3 {
    align-items: center;
    flex-direction: column;
  }
  .gallery2-image13 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-image23 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
  .gallery2-image33 {
    flex: 0 0 auto;
    width: 100%;
    height: 400px;
  }
}
@media(max-width: 479px) {
  .gallery2-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .gallery2-content2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .gallery2-content3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
