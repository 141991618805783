.logos7-container10 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1512389055488-8d82cb26ba6c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDc1fHxiYWNrZ3JvdW5kfGVufDB8fHx8MTcyNDQ4MjY4Nnww&ixlib=rb-4.0.3&w=1500");
}
.logos7-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.logos7-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logos7-container12 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 285px;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.logos7-container13 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo110 {
  width: 100px;
  height: 59px;
  object-fit: contain;
}
.logos7-container14 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo111 {
  object-fit: contain;
}
.logos7-container15 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo112 {
  width: 104px;
  height: 62px;
  object-fit: contain;
}
.logos7-container16 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo113 {
  object-fit: contain;
}
.logos7-container17 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo114 {
  flex: 1;
  width: 100%;
  height: 50px;
  object-fit: contain;
}
.logos7-container18 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo115 {
  object-fit: contain;
}
.logos7-container19 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo116 {
  object-fit: contain;
}
.logos7-container20 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo117 {
  object-fit: contain;
}
.logos7-container21 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo118 {
  object-fit: contain;
}
.logos7-container22 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo119 {
  object-fit: contain;
}
.logos7-container23 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo120 {
  object-fit: contain;
}
.logos7-container24 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo121 {
  object-fit: contain;
}
.logos7-container25 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo122 {
  object-fit: contain;
}
.logos7-container26 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo123 {
  object-fit: contain;
}
.logos7-container27 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo124 {
  object-fit: contain;
}
.logos7-container28 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo125 {
  object-fit: contain;
}
.logos7-container29 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo126 {
  object-fit: contain;
}
.logos7-container30 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo127 {
  object-fit: contain;
}
.logos7-container31 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo128 {
  object-fit: contain;
}
.logos7-container32 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo129 {
  object-fit: contain;
}
.logos7-container33 {
  gap: 0;
  flex: 0 0 auto;
  width: 120px;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logos7-logo130 {
  object-fit: contain;
}
.logos7-text2 {
  display: inline-block;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 800;
}
.logos7root-class-name {
  left: -1px;
  bottom: 0px;
  position: absolute;
}
@media(max-width: 991px) {
  .logos7-container12 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding: var(--dl-space-space-threeunits);
  }
  .logos7-container13 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container14 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container15 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container16 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container17 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container18 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container19 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container20 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container21 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container22 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container23 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container24 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container25 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container26 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container27 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container28 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container29 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container30 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container31 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container32 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container33 {
    width: 30%;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .logos7-container12 {
    padding: var(--dl-space-space-twounits);
  }
  .logos7-container13 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container14 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container15 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container16 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container17 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container18 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container19 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container20 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container21 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container22 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container23 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container24 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container25 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container26 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container27 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container28 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container29 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container30 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container31 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container32 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
  .logos7-container33 {
    width: 40%;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .logos7-container10 {
    gap: var(--dl-space-space-unit);
  }
  .logos7-container12 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
  }
  .logos7-container13 {
    width: 45%;
  }
  .logos7-container14 {
    width: 45%;
  }
  .logos7-container15 {
    width: 45%;
  }
  .logos7-container16 {
    width: 45%;
  }
  .logos7-container17 {
    width: 45%;
  }
  .logos7-container18 {
    width: 45%;
  }
  .logos7-container19 {
    width: 45%;
  }
  .logos7-container20 {
    width: 45%;
  }
  .logos7-container21 {
    width: 45%;
  }
  .logos7-container22 {
    width: 45%;
  }
  .logos7-container23 {
    width: 45%;
  }
  .logos7-container24 {
    width: 45%;
  }
  .logos7-container25 {
    width: 45%;
  }
  .logos7-container26 {
    width: 45%;
  }
  .logos7-container27 {
    width: 45%;
  }
  .logos7-container28 {
    width: 45%;
  }
  .logos7-container29 {
    width: 45%;
  }
  .logos7-container30 {
    width: 45%;
  }
  .logos7-container31 {
    width: 45%;
  }
  .logos7-container32 {
    width: 45%;
  }
  .logos7-container33 {
    width: 45%;
  }
}
