.gallery-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.gallery-text10 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  width: var(--dl-size-size-maxwidth);
  height: auto;
  display: inline-block;
  font-size: 35px;
  font-family: "Poppins";
  font-weight: 700;
    white-space: nowrap; 
}
.gallery-text11 {
  display: inline-block;
  text-decoration: none;
}
.gallery-text12 {
  display: inline-block;
}
.gallery-text15 {
  display: inline-block;
  text-decoration: none;
}
.gallery-text16 {
  display: inline-block;
}
.gallery-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.gallery-text18 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.gallery-text19 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.gallery-text20 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.gallery-text21 {
  display: inline-block;
  text-decoration: none;
}
.gallery-text22 {
  display: inline-block;
}
.gallery-text23 {
  display: inline-block;
}
.gallery-text24 {
  display: inline-block;
}
.gallery-text25 {
  display: inline-block;
}
.gallery-text26 {
  display: inline-block;
}
.gallery-text27 {
  display: inline-block;
}
.gallery-text28 {
  display: inline-block;
  font-family: "Poppins";
}
.gallery-text29 {
  display: inline-block;
  font-family: "Poppins";
}
.gallery-text30 {
  display: inline-block;
  text-align: center;
}
