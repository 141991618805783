.gallery4-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.gallery4-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery4-section-title {
  gap: 24px;
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery4-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery4-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery4-slider {
  width: 100%;
  height: 600px;
  display: inline-block;
}
.gallery4-slider-wrapper {
  width: 100%;
}
.gallery4-slider-slide1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
}
.gallery4-container2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery4-container3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery4-slider-slide2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
}
.gallery4-container4 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery4-container5 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery4-slider-slide3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
}
.gallery4-container6 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image5 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery4-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image6 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery4-slider-pagination {
  display: block;
}
.gallery4-text2 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 991px) {
  .gallery4-content {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .gallery4-section-title {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .gallery4-slider-slide1 {
    flex-direction: column;
  }
  .gallery4-container2 {
    height: calc(50% - 8px);
  }
  .gallery4-container3 {
    height: calc(50% - 8px);
  }
  .gallery4-slider-slide2 {
    flex-direction: column;
  }
  .gallery4-container4 {
    height: calc(50% - 8px);
  }
  .gallery4-container5 {
    height: calc(50% - 8px);
  }
  .gallery4-slider-slide3 {
    flex-direction: column;
  }
  .gallery4-container6 {
    height: calc(50% - 8px);
  }
  .gallery4-container7 {
    height: calc(50% - 8px);
  }
}
@media(max-width: 479px) {
  .gallery4-slider {
    height: 440px;
  }
}
