.gallery3-gallery3 {
  width: 100%;
  height: 762px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.gallery3-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery3-section-title {
  gap: 24px;
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery3-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery3-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  height: 600px;
  display: flex;
  overflow: auto;
}
.gallery3-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery3-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery3-image6 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery3-image7 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery3-text2 {
  display: inline-block;
  font-family: "Poppins";
}
@media(max-width: 767px) {
  .gallery3-section-title {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .gallery3-content {
    height: 450px;
  }
}
