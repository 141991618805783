.gallery1-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 1200px;
  height: 1810px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 113px;
  flex-direction: column;
}
.gallery1-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.gallery1-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.gallery1-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.gallery1-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.gallery1-row-container1 {
  width: 100%;
}
.gallery1-placeholder-image10 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image11 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image12 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image13 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image14 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image15 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image16 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image17 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image18 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image19 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image20 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image21 {
  width: 400px;
  height: 400px;
}
.gallery1-row-container2 {
  width: 100%;
}
.gallery1-placeholder-image22 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image23 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image24 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image25 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image26 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image27 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image28 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image29 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image30 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image31 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image32 {
  width: 400px;
  height: 400px;
}
.gallery1-placeholder-image33 {
  width: 400px;
  height: 400px;
}
.gallery1-text2 {
  display: inline-block;
}
.gallery1-container2 {
  display: contents;
}
.gallery1-text3 {
  display: inline-block;
  font-family: "Poppins";
}
.gallery1root-class-name {
  flex: 1;
}
@media(max-width: 767px) {
  .gallery1-content2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .gallery1-actions {
    width: 100%;
    flex-direction: column;
  }
}
